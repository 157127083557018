import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo'
import Hero from '../../../components/cloud-studio/hero'
import StickyCta from '../../../components/cloud-studio/sticky-cta'
import Card from '../../../components/cloud-studio/card'
import CardIcon from '../../../components/cloud-studio/card-icon'
import CardFullWidthImage from '../../../components/cloud-studio/card-full-width-image'
import SectionTitle from '../../../components/cloud-studio/section-title'
import Accordion from '../../../components/cloud-studio/accordion'
import Table from '../../../components/cloud-studio/table'
import Button from '../../../components/cloud-studio/button'
import Chip from '../../../components/cloud-studio/chip'

// Assets
import Power from '../../../images/cloud-studio/power.inline.svg'
import Isolation from '../../../images/cloud-studio/isolation.inline.svg'
import Security from '../../../images/cloud-studio/security.inline.svg'
import IconRightArrow from '../../../images/arrow-right-alt.inline.svg'

function CloudMetalPage() {
  const images = useStaticQuery(graphql`
    {
      cloudMetalHero: file(
        relativePath: { eq: "cloud-studio/cloud-metal-hero.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      totalControl: file(
        relativePath: { eq: "cloud-studio/total-control.png" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            transformOptions: { fit: CONTAIN }
          )
        }
      }
    }
  `)

  return (
    <Layout
      mainClass={'bg-[#0D0E11] font-roobert'}
      headerVariant={'dark'}
      footerVariant={'dark'}
    >
      <Seo
        post={{
          seo: {
            title: 'Bare Metal Cloud Hosting | Liquid Web Cloud Metal',
            metaDesc:
              'Bare metal cloud hosting by Cloud Studio blends bare metal power and cloud convenience for a solution that’s effective and efficient. Explore now.',
          },
        }}
      />
      <Hero
        label="BARE METAL CLOUD HOSTING"
        title="Cloud Metal: Cloud convenience and "
        rotatingTitles={['bare metal—combined']}
        subtitle="High-performance single-tenant servers plus our powerhouse platform"
        image={images?.cloudMetalHero}
        imagePosition="right"
      />

      <StickyCta
        title="Build your Cloud Metal server, starting at $115 a month"
        url="/configuration/cloud-studio/?type=metal"
      />

      <div className="container mb-32">
        <h2
          className={classnames(
            'text-base',
            'text-white',
            'font-normal',
            'uppercase',
            'mt-0',
            'mb-6',
            'tracking-[0.8px]'
          )}
        >
          Bare Metal Cloud Benefits
        </h2>
        <div
          className={classnames('grid', 'gap-6', 'lg:gap-12', 'lg:grid-cols-3')}
        >
          <CardIcon
            icon={<Power />}
            content="Power. The entirety of your server’s resources are always available when you need them."
          />
          <CardIcon
            icon={<Isolation />}
            content="Isolation. Your server’s performance and security are never affected by noisy neighbors."
          />
          <CardIcon
            icon={<Security />}
            content="Growth. Jump to more robust hardware minus a costly migration with one-click resizes."
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="Cloud Metal plans" />
        <Table>
          <tbody>
            <tr className="uppercase tracking-widest whitespace-nowrap">
              <td>CPU</td>
              <td align="center">Cores</td>
              <td align="center">Memory</td>
              <td align="center">Storage</td>
              <td align="center">Transfer</td>
              <td align="center">Monthly Cost</td>
              <td align="center">Hourly Cost</td>
              <td></td>
            </tr>
            <tr>
              <td className="font-bold">E-2334 @ 3.4GHz</td>
              <td align="center">4</td>
              <td align="center">16 GB</td>
              <td align="center">2x 480 GB SSD (RAID-1)</td>
              <td align="center">10,000</td>
              <td align="center">
                <Chip label="$115" />
              </td>
              <td align="center" className="text-[#959595]">
                $0.156
              </td>
              <td>
                <Button
                  className={classnames('!text-base', 'whitespace-nowrap')}
                  text="Build Now"
                  icon={<IconRightArrow />}
                  url="/configuration/cloud-studio/?type=metal"
                  variant="plain"
                />
              </td>
            </tr>
            <tr>
              <td className="font-bold">E-2336 @ 2.9GHz</td>
              <td align="center">6</td>
              <td align="center">32 GB</td>
              <td align="center">2x 480 GB SSD (RAID-1)</td>
              <td align="center">10,000</td>
              <td align="center">
                <Chip label="$140" />
              </td>
              <td align="center" className="text-[#959595]">
                $0.192
              </td>
              <td>
                <Button
                  className={classnames('!text-base', 'whitespace-nowrap')}
                  text="Build Now"
                  icon={<IconRightArrow />}
                  url="/configuration/cloud-studio/?type=metal"
                  variant="plain"
                />
              </td>
            </tr>
            <tr>
              <td className="font-bold">6226R (Single) @ 2.9GHz</td>
              <td align="center">16</td>
              <td align="center">48 GB</td>
              <td align="center">1.5 TB SSD (RAID-10)</td>
              <td align="center">10,000</td>
              <td align="center">
                <Chip label="$375" />
              </td>
              <td align="center" className="text-[#959595]">
                $0.514
              </td>
              <td>
                <Button
                  className={classnames('!text-base', 'whitespace-nowrap')}
                  text="Build Now"
                  icon={<IconRightArrow />}
                  url="/configuration/cloud-studio/?type=metal"
                  variant="plain"
                />
              </td>
            </tr>
            <tr>
              <td className="font-bold">6226R (Dual) @ 2.9GHz</td>
              <td align="center">32</td>
              <td align="center">128 GB</td>
              <td align="center">3 TB SSD (RAID-10)</td>
              <td align="center">10,000</td>
              <td align="center">
                <Chip label="$475" />
              </td>
              <td align="center" className="text-[#959595]">
                $0.651
              </td>
              <td>
                <Button
                  className={classnames('!text-base', 'whitespace-nowrap')}
                  text="Build Now"
                  icon={<IconRightArrow />}
                  url="/configuration/cloud-studio/?type=metal"
                  variant="plain"
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className="container mb-32">
        <SectionTitle
          title="Bare metal cloud hosting that’s just right"
          className="max-w-full"
        />
        <div className="grid gap-y-10 lg:gap-x-10 lg:grid-cols-12">
          <Card
            className="col-span-12 lg:col-span-7"
            variant="dark"
            label="Management"
            title="Select the management that suits you"
            description="Multiple levels of responsibility and control ensure your hardware, operating system, and applications meet your particular needs."
          />
          <Card
            className="col-span-12 lg:col-span-5"
            variant="dark"
            label="Tailoring"
            title="Handpick your hardware"
            description="Know what’s in your server—choose the exact CPU model you want."
          />
          <CardFullWidthImage
            className="col-span-12"
            variant="dark"
            label="Development"
            title="Work with an easy-to-use API"
            description="Our API was designed with developers in mind. Use it in your dev workflow, autotest software, or integrate our infrastructure into your own hosting service."
            link={{
              url: '/products/cloud-hosting/cloud-api',
              text: 'Learn more',
            }}
            image={images?.totalControl}
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="A highly flexible solution" />
        <div className="grid gap-10 lg:grid-cols-2">
          <Card
            variant="light"
            label="Scalable"
            title="Painless scaling"
            description="Upgrade your CPU, RAM, and storage without migration challenges and expenses."
          />
          <Card
            variant="dark"
            label="Reliable"
            title="Contingency planning"
            description="Maintain a path to recovery with available automated backups and snapshots."
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="Bare metal cloud FAQs" />
        <Accordion
          className="border-t border-[#3A3A3A]"
          title="What is bare metal cloud?"
        >
          <p>
            Bare metal cloud is a type of cloud computing that adds a
            virtualization layer to traditional bare metal servers. Bare metal
            cloud servers combine the benefits of physical servers and cloud
            servers, offering a completely isolated, single-tenant environment
            with customizable configurations.
          </p>
        </Accordion>
        <Accordion title="What is Liquid Web Cloud Metal?">
          <p>
            Cloud Metal is Liquid Web’s bare metal cloud solution. It’s a
            single-tenant physical server that uses virtualization to provide
            the same features available in our{' '}
            <Link to="/products/cloud-hosting/vps/">Cloud VPS</Link> product
            line. Cloud Metal runs on our{' '}
            <Link to="/products/cloud-hosting/">Cloud Studio platform</Link>.
          </p>
        </Accordion>
        <Accordion title="How much can I expect to pay?">
          <p>
            Cloud Metal starts at $115/mo. Prices are based on your unique
            resource needs. <Link to="/contact-us/">Contact us</Link> to learn
            more.
          </p>
        </Accordion>
        <Accordion title="How do I get started?">
          <p>
            Click the “Build” banner and configure your virtual server however
            you’d like. It’s really that easy!
          </p>
        </Accordion>
      </div>
    </Layout>
  )
}

export default CloudMetalPage
